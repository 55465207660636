@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/global.scss';
.carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  gap: px(10);
  .swipeMain {
    width: 100%;
    .slide {
      @include v-center;
      img {
        width: 100%;
        border-radius: px(20);
      }
    }
  }
  .swipeThumb {
    padding: px(10);
    width: 100%;
    max-width: 570px;

    .slide {
      cursor: pointer;
      &.active img {
        aspect-ratio: 1 / 1;
        // transition: all $time-slow ease-in-out;
      }
      @include v-center;
      img {
        width: 100%;
        border-radius: px(20);
        aspect-ratio: 122 / 105;
        object-fit: cover;
      }
    }
  }
}
