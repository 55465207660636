@mixin breakpoint-mobile {
  @media only screen and (max-width: $sm) {
    @content;
  }
}

@mixin breakpoint-ipad {
  @media only screen and (max-width: $ipad) {
    @content;
  }
}

@mixin container {
  width: 100%;
  max-width: $max;
  margin: 0 auto;
  padding: 0 42px;
  @include breakpoint-ipad {
    padding: 0 15px;
  }
}

@mixin v-center {
  display: flex;
  align-items: center;
}
@mixin h-center {
  display: flex;
  justify-content: center;
}

@mixin native-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

@mixin modal {
  background-color: $color-white;
  position: fixed;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}
