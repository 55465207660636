@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/global.scss';
.container {
  width: 100vw;
  display: block;
  position: relative;
  margin-bottom: px(60);
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    @include breakpoint-ipad {
      grid-template-columns: 1fr;
    }
    @include container;
    .carousel {
      flex: 1;
    }
    .details {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
}
