@import '../../../../styles/variables.scss';
@import '../../../../styles/global.scss';
@import '../../../../styles/mixins.scss';
.wrapper {
  position: relative;
  @include breakpoint-mobile {
    display: none;
  }
  .button {
    @include v-center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: $font-family-medium;
    font-size: px(16);
    line-height: px(22);
    min-width: px(108);
    justify-content: flex-end;
    gap: px(10);
  }
  .list {
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    z-index: 10;
    padding: px(10) px(6);
    background-color: $color-gray-1;
    box-shadow: 0px 3px 5px #0000002e;
    border-radius: $radius;
    .item {
      border-radius: $radius;
      padding: px(10) px(15);
      cursor: pointer;
      margin-bottom: 2px;
      &:hover {
        background-color: $color-black;
        color: $color-white;
      }
      &.active {
        background-color: #d4dbe0;
      }
    }
  }
}
