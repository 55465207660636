@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/global.scss';

.modal {
  .content {
    @include modal;
    padding: px(10) px(20);
    display: flex;
    flex-direction: column;
    gap: px(30);
    border-radius: $radius;
    max-width: 500px;
    .title {
      position: relative;
      .close {
        width: fit-content;
        position: absolute;
        top: 0;
        right: 0;
        padding: px(8) px(15);
        border-radius: 5rem;
        color: $color-gray-2;
      }
    }
    .nav {
      display: flex;
      flex-direction: column;
      margin-bottom: px(40);
      a {
        padding: px(5) px(4);
        color: $color-black;
        &:hover {
          background-color: $color-gray-1;
          border-radius: $radius;
        }
      }
    }
  }
  &::after {
    content: ' ';
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 200vh;
    z-index: 199;
    left: 0;
    top: 0;
  }
}
