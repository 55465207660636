@import '../../../styles/variables.scss';
@import '../../../styles/global.scss';
@import '../../../styles/mixins.scss';
.form {
  display: flex;
  align-items: center;
  gap: px(10);

  .control {
    @include v-center;
    gap: px(10);
    button {
      @include native-button;
    }
    input {
      height: px(42);
      font-size: px(16);
      padding: 0 px(10);
      max-width: px(400);
      width: px(400);
      border: none;
      outline: none;
      &::placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
      @include breakpoint-ipad {
        display: none;
      }
    }
  }
  &.isModal {
    .control input {
      max-width: px(300);
      @include breakpoint-ipad {
        display: block;
        width: 100%;
      }
    }
    .categories {
      button {
        min-width: fit-content;
      }
      ul {
        left: 0;
      }
      input {
      }
      @include breakpoint-mobile {
        display: block;
      }
    }
  }
}
