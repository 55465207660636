@import '../../../../../styles/variables.scss';
@import '../../../../../styles/global.scss';
@import '../../../../../styles/mixins.scss';
.rate {
  display: flex;
  gap: px(28);

  .count {
    font-family: $font-family;
    font-size: px(18);
    line-height: px(32);
    @include v-center;
    .votes {
      color: $color-gray-2;
    }
  }
}
.stars {
  @include v-center;
  span {
    @include v-center;
  }
  gap: px(8);
}
