@import '../../../../styles/variables.scss';
@import '../../../../styles/global.scss';
@import '../../../../styles/mixins.scss';
.wrapper {
  .title {
    font-family: $font-family-semibold;
    font-size: px(24);
    line-height: px(32);
    color: $color-black;
    padding: px(10) 0;
  }
  .flex {
    display: flex;
    gap: px(52);
    @include breakpoint-mobile {
      gap: px(15);
      flex-wrap: wrap;
    }
    .code {
      @include v-center;
      font-family: $font-family-medium;
      font-size: px(16);
      line-height: px(22);
      color: $color-gray-2;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    gap: px(10);
  }
}
