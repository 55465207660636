@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/global.scss';
.wrapper {
  .total {
    @include v-center;
    gap: px(10);
    padding: px(10) 0;
    margin-bottom: px(10);
    h4 {
      font-family: $font-family-semibold;
      font-size: px(20);
      line-height: px(28);
      color: $color-black;
      padding: px(10) 0;
      width: fit-content;
    }
    dl {
      font-family: $font-family-semibold;
      font-size: px(24);
      line-height: px(32);
      color: $primary-color-green;
    }
  }
  .footerDetails {
    @include v-center;
    gap: px(10);
    flex-wrap: wrap;
    .button {
      height: px(48);
      @include breakpoint-mobile {
        flex: 1;
        justify-content: center;
      }
    }
    .available {
      @include breakpoint-mobile {
        flex: 100%;
        text-align: center;
      }
      font-size: px(12);
      color: $color-gray-3;
      padding: px(10) px(20);
    }
  }
}
