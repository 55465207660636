/*Poppins*/
@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Semibold';
  src: local('Poppins Semibold'),
    url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Extrabold';
  src: local('Poppins Extrabold'),
    url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Black';
  src: local('Poppins Black'), url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
