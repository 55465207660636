// Breakpoints
$usm: 375px;
$sm: 767px;
$md: 991px;
$lg: 1199px;
$xl: 1200px;
$max: 1400px;
$ipad: 1024px;

// Colors
$color-black: #111;
$color-white: #fff;
$color-gray-1: #ecf1f4;
$color-gray-2: #999cad;
$color-gray-3: #828282;
$color-red-1: #f95659;
$primary-color-blue: #0f3043;
$primary-color-blue-hover: #0c2737;
$primary-color-green: #38b349;
$primary-color-green-hover: #2a9438;

// Poppins
$font-family-light: 'Poppins Light', sans-serif;
$font-family: 'Poppins', sans-serif;
$font-family-medium: 'Poppins Medium', sans-serif;
$font-family-bold: 'Poppins Bold', sans-serif;
$font-family-semibold: 'Poppins Semibold', sans-serif;
$font-family-extrabold: 'Poppins Extrabold', sans-serif;
$font-family-black: 'Poppins Black', sans-serif;

$time-fast: 150ms;
$time-slow: 300ms;

$radius: 10px;
